import React from "react";
import {
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import settings from "../../../../settings";

const useStyles = makeStyles((theme) => ({}));

const getDateFormat = (date) => {
    const d = new Date(date);
    const year = d.getUTCFullYear();
    const month = d.getUTCMonth();
    const nDate = d.getUTCDate();

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    let dateFormat = months[month] + " " + nDate + ", " + year;
    return dateFormat;
};

export default function PreviousContracts(props) {
    const classes = useStyles();
    const [selectedHistoryYear, setSelectedHistoryYear] = useState(0);
    const [selectedYearsHistory, setselectedYearsHistory] = useState({});
    const [historyYears, setHistoryYears] = useState([
        2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014,
    ]);

    useEffect(() => {        
        if (localStorage.getItem("selectedYearForHistoricalContracts") !== undefined) {
            setSelectedHistoryYear(JSON.parse(localStorage.getItem("selectedYearForHistoricalContracts")));
        }

        return () => {};
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("pes-crm-token");
        if (selectedHistoryYear !== 0) {
            fetch(settings.api().schools + `/${props.match.params.id}/orders/${selectedHistoryYear}`, {
                // fetch(settings.api().schools + `/3686/orders/2024`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else {
                        const error = new Error(res.error);
                        throw error;
                    }
                })
                .then((data) => {
                    // console.log(data);

                    setselectedYearsHistory(data);
                });
        }

        return () => {};
    }, [selectedHistoryYear]);
    return (
        <div>
            <Typography>View Historical Contracts</Typography>

            <FormControl fullWidth required style={{ width: "500px" }}>
                <InputLabel id="primary">Year</InputLabel>
                <Select
                    labelId="year"
                    value={selectedHistoryYear}
                    name="historyYear"
                    onChange={(e) => {
                        setSelectedHistoryYear(parseInt(e.target.value));
                        localStorage.setItem("selectedYearForHistoricalContracts", e.target.value);
                    }}
                >
                    <MenuItem>--Select--</MenuItem>
                    {historyYears &&
                        historyYears.map((c) => (
                            <MenuItem value={c} key={c}>
                                {c}
                            </MenuItem>
                        ))}
                </Select>

                {selectedYearsHistory.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Planner/Component</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="center">Qty</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedYearsHistory?.map((cont) => (
                                    <React.Fragment key={cont.orderId}>
                                        {/* Order Information Row */}
                                        <TableRow>
                                            <TableCell colSpan={3}>
                                                <strong>
                                                    Order: {cont.orderId}, Status: {cont.orderStatus}, Shipping: $
                                                    {cont.orderShipping.toFixed(2)}
                                                </strong>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={3}>
                                                <strong>Delivery Date:</strong> {getDateFormat(cont.deliveryDate)}
                                            </TableCell>
                                        </TableRow>

                                        {/* Map through details */}
                                        {cont.details.map((detail, detailIndex) => (
                                            <React.Fragment key={`${cont.orderId}-${detailIndex}`}>
                                                {/* Row for each detail */}
                                                <TableRow>
                                                    <TableCell style={{ paddingLeft: "20px" }}>
                                                        {detail.planner}
                                                    </TableCell>
                                                    <TableCell>{detail.price.toFixed(2)}</TableCell>
                                                    <TableCell>{detail.quantity}</TableCell>
                                                </TableRow>

                                                {/* Rows for each option under the detail */}
                                                {detail.options.map((option, optionIndex) => (
                                                    <TableRow key={`${cont.orderId}-${detailIndex}-${optionIndex}`}>
                                                        <TableCell style={{ paddingLeft: "40px" }}>
                                                            {option.componentName}
                                                        </TableCell>
                                                        <TableCell>{option.price.toFixed(2)}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                ))}

                                                {detail.schoolPages.map((sP, spIndex) => (
                                                    <TableRow key={`${cont.orderId}-${detailIndex}-schoolPages`}>
                                                        <TableCell style={{ paddingLeft: "40px" }}>
                                                            School Pages: {sP.name}
                                                        </TableCell>
                                                        <TableCell>{sP.price.toFixed(2)}</TableCell>
                                                        <TableCell>{sP.quantity}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography>There are no records</Typography>
                )}
            </FormControl>
        </div>
    );
}
