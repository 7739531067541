import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import withAuth from "../withAuth";
import Alerts from "../components/Portal/Alerts/Alerts";
import Contact from "../components/Portal/Contacts/Contact";
import Contacts from "../components/Portal/Contacts/Contacts";
import Schools from "../components/Portal/Schools/Schools";
import School from "../components/Portal/Schools/School";
import Orders from "../components/Portal/Orders/Orders";
import Order from "../components/Portal/Orders/Order";
import Reps from "../components/Portal/Reps/Reps";
import Rep from "../components/Portal/Reps/Rep";
import Dashboard from "../components/Portal/Dashboard/Dashboard";
import Profile from "../components/Portal/Profile/Profile";
import PortalError from "../components/Portal/Errors/PortalError";
import AllContracts from "../components/Portal/AllContracts/AllContracts";
import Map from "../components/Portal/Map/Map";
import PreviousContracts from "../components/Portal/Schools/School/PreviousContracts";
// import Map from "../components/Portal/Map/Map2";

class AppRouter extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/crm/" exact component={withAuth(Dashboard)} />
                    <Route path="/crm/alerts" exact component={withAuth(Alerts)} />
                    <Route path="/crm/schools" exact component={withAuth(Schools)} />
                    <Route path="/crm/contacts" exact component={withAuth(Contacts)} />
                    <Route path="/crm/reps" exact component={withAuth(Reps)} />
                    <Route path="/crm/reps/:id" exact component={withAuth(Rep)} />
                    <Route path="/crm/profile" exact component={withAuth(Profile)} />
                    <Route path="/crm/contacts/:id" exact component={withAuth(Contact)} />
                    <Route path="/crm/schools/:id" exact component={withAuth(School)} />
                    <Route path="/crm/schools/:id/historical_contracts" exact component={withAuth(PreviousContracts)} />
                    <Route path="/crm/orders" exact component={withAuth(Orders)} />
                    <Route path="/crm/orders/:id" exact component={withAuth(Order)} />
                    <Route path="/crm/contracts" exact component={withAuth(AllContracts)} />
                    <Route path="/crm/map" exact component={withAuth(Map)} />

                    <Route path="*" component={withAuth(PortalError)} />
                </Switch>
            </div>
        );
    }
}

export default AppRouter;
